import adaptiveCssValue from "../helpers/adaptiveCssValue";

const colors = {
    white: "#ffffff",
    dirtyWhite: "#e2e2e2",
    lightGrey: "#c5c5c5",
    midGrey: "#c5c5c5",
    midGrey2: "#aaaaaa",
    midGrey3: "#666666",
    darkGrey: "#333333",
    black: "#000000",
    green: "#2CFF48"
};

const colorsInUse = {
    main: colors.black,
    mainSoft: colors.darkGrey,
    spot1: colors.green,
    background: colors.white,
    background2: colors.dirtyWhite,
    disabled: colors.midGrey
};

const breakpoints = [0, 360, 640, 768, 1024, 1280, 1440, 1920];
const breakpointAlias = ["sm", "md", "lg", "xl", "xxl", "x3l", "x4l", "x5l"];
const breakpointByAlias = breakpoints.reduce(
    (accu, value, index) => ({
        ...accu,
        [breakpointAlias[index]]: value
    }),
    {}
);
const grid = {
    sm: {
        margin: 3,
        gutter: 2,
        unit: "rem"
    },

    x5l: {
        margin: 8,
        gutter: 6,
        unit: "rem"
    }
};

const bodyBorder = {
    sm: {
        width: 1.2,
        unit: "rem"
    },
    x5l: {
        width: 2,
        unit: "rem"
    }
};

// extend grid so that every breakpoint inherits from its preceding one
// good for functions which don’t know if a breakpoint exists
const extendedGrid = breakpointAlias.reduce(
    (state, bp) => {
        // get last breakpoint
        const lastBreakpoint = Object.values(state).pop();
        let defaultBp = grid[bp] || {};
        state[bp] = {
            ...lastBreakpoint,
            ...defaultBp
        };
        return state;
    },
    {
        sm: grid.sm
    }
);
export default {
    bodyBorder,
    fluidBodyBorder: {
        mdToX5l: {
            width: adaptiveCssValue(
                breakpointByAlias["md"],
                breakpointByAlias["x5l"],
                bodyBorder.sm.width,
                bodyBorder.x5l.width
            )
        }
    },
    breakpoints,
    breakpointAlias,
    breakpointByAlias,
    colors: {
        ...colors,
        ...colorsInUse
    },
    grid,
    extendedGrid,
    fluidGrid: {
        mdToX5l: {
            gutter: adaptiveCssValue(
                breakpointByAlias["md"],
                breakpointByAlias["x5l"],
                grid.sm.gutter,
                grid.x5l.gutter
            ),
            margin: adaptiveCssValue(
                breakpointByAlias["md"],
                breakpointByAlias["x5l"],
                grid.sm.margin,
                grid.x5l.margin
            )
        }
    }
};

const mediaquery = (
    breakpointAlias,
    styles
) => `@media (min-width: ${breakpointByAlias[breakpointAlias]}px) {
  ${styles}
}`;

export { mediaquery };
