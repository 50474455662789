/** Calculates a css calc() value for a given. */
export default function adaptiveCssValue(
    browserWidthFrom = 320,
    browserWidthTo = 1280,
    from = 1,
    to = 2,
    unitRem = true,
    decimals = 4
) {
    if (unitRem) {
        from *= 10;
        to *= 10;
    }
    decimals = 10 ** 4;
    let baseSize =
        (to * browserWidthFrom - from * browserWidthTo) /
        (browserWidthFrom - browserWidthTo);
    const flexSize = (from - baseSize) / browserWidthFrom;

    if (unitRem) {
        baseSize /= 10;
    }

    const fixedPart = `${Math.round(baseSize * decimals) / decimals}${
        unitRem ? "rem" : "px"
    }`;

    return `calc(${fixedPart} + ${Math.round(flexSize * 100 * decimals) /
        decimals}vw)`;
}
