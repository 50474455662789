/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useState } from "react";
/** @jsx jsx */
import { Global, jsx, css } from "@emotion/core";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { Link as UnstyledLink } from "gatsby";
// import { ThemeProvider } from 'emotion-theming'
import adaptiveCssValue from "../helpers/adaptiveCssValue";

import Cell from "./grid/Cell";
import Row from "./grid/Row";
import Grid from "./grid/Grid";
import Wrapper from "./grid/Wrapper";
import MenuButton from "./MenuButton";
import Menu from "./Menu";
import { Text } from "../components/text";
import stamp from "../images/stamp-fucking-awesome-botto.svg";
import "./layout.scss";
import "../styles/fonts.scss";
import theme, { mediaquery } from "../styles/theme";
import config from "../../app-config";
import burger from "../images/burger.png";
import Watcher from "@fuckingdigital/watcher";

const doubleFluidBorder = adaptiveCssValue(
    theme.breakpointByAlias["md"],
    theme.breakpointByAlias["x5l"],
    theme.bodyBorder.sm.width * 2,
    theme.bodyBorder.x5l.width * 2
);

let watchers = {};
if (typeof window !== "undefined") {
    watchers.scroll = new Watcher(() => window.pageYOffset);
    watchers.size = new Watcher(() => [window.innerWidth, window.innerHeight]);
    window.__WATCHERS__ = watchers;
}

const Link = styled(UnstyledLink)`
    position: relative;
    z-index: 10;
    text-decoration: none;
    margin-right: 2em;
    box-shadow: inset 0 0 ${theme.colors.spot1};
    transition: box-shadow 0.2s;

    &:hover {
        box-shadow: inset 0 -0.4em ${theme.colors.spot1};
    }
`;

const Layout = ({ children }) => {
    const [menuOpen, setMenuOpen] = useState(false),
        [didScroll, setDidScroll] = useState(false);

    useEffect(() => {
        const namespace = "setDidScroll";
        if (typeof window !== "undefined" && window.__WATCHERS__) {
            window.__WATCHERS__.scroll.addWatch({
                namespace,
                matchCondition: scrollTop => scrollTop > 0,
                onappear() {
                    setDidScroll(true);
                },
                ondisappear() {
                    setDidScroll(false);
                }
            });
        }
        return () => window.__WATCHERS__.scroll.removeNamespace(namespace);
    });

    const toggleMenu = () => setMenuOpen(!menuOpen);
    return (
        <>
            <Global
                styles={css`
                    body {
                        cursor: default;
                        color: ${theme.colors.main};
                        font-weight: normal;
                        word-wrap: break-word;
                        font-kerning: normal;
                        -moz-font-feature-settings: "kern", "liga", "clig",
                            "calt";
                        -ms-font-feature-settings: "kern", "liga", "clig",
                            "calt";
                        -webkit-font-feature-settings: "kern", "liga", "clig",
                            "calt";
                        font-feature-settings: "kern", "liga", "clig", "calt";
                        background-color: ${theme.colors.spot1};
                        margin: 0;
                        font-weight: 400;
                        font-size: ${adaptiveCssValue(
                            theme.breakpointByAlias["md"],
                            theme.breakpointByAlias["x5l"],
                            1.4,
                            2.6
                        )};
                        line-height: ${adaptiveCssValue(
                            theme.breakpointByAlias["md"],
                            theme.breakpointByAlias["x5l"],
                            2.6,
                            4.2
                        )};

                        &::before,
                        &::after {
                            content: " ";
                            display: block;
                            position: fixed;
                        }

                        &::before {
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: ${theme.fluidBodyBorder.mdToX5l.width};
                            background-color: ${theme.colors.spot1};
                            z-index: 99999;
                        }
                        &::after {
                            top: ${theme.fluidBodyBorder.mdToX5l.width};
                            left: ${theme.fluidBodyBorder.mdToX5l.width};
                            width: calc(100% - ${doubleFluidBorder});
                            height: calc(100vh - ${doubleFluidBorder});
                            // background: url(${burger}) center no-repeat;
                            background-color: ${theme.colors.background};
                            z-index: -1;
                        }
                    }

                    ${Text} a[href] {
                        position: relative;
                        z-index: 10;
                        text-decoration: none;
                        transition: box-shadow .2s;
                        box-shadow: inset 0 -2px ${theme.colors.spot1};

                        &:hover {
                            box-shadow: inset 0 -.5em ${theme.colors.spot1};
                        }
                    }
                `}
            />
            {/* <Header siteTitle={data.site.siteMetadata.title} /> */}
            <MenuButton
                css={css`
                    position: fixed;
                    right: calc(${theme.fluidGrid.mdToX5l.margin} - 8px);
                    top: calc(${theme.fluidGrid.mdToX5l.margin} - 8px);
                    margin-left: auto;
                    z-index: 99999;

                    ${mediaquery(
                        "xxl",
                        `
                        opacity: 0.001;
                        transform: scale3d(0,0,1);
                        transition: opacity .25s, transform .25s cubic-bezier(.35,1.65,.35,1);
                        ${
                            didScroll
                                ? `
                            opacity: 1;
                            transform: scale3d(1,1,1);`
                                : ""
                        }
                    `
                    )}
                `}
                switchedOn={menuOpen}
                onclick={toggleMenu}
            />
            <Menu
                hidden={!menuOpen}
                items={config.navItems}
                onclick={toggleMenu}
            />
            <Wrapper
                css={css`
                    position: absolute;
                    top: ${theme.fluidGrid.mdToX5l.margin};
                    left: ${theme.fluidGrid.mdToX5l.margin};
                `}
            >
                <Row>
                    {/* <Cell span={{ xxl: 4 / 12 }}>
                        <Text>
                            <UnstyledLink
                                to="/"
                                css={css`
                                    text-decoration: none;
                                `}
                            >
                                fucking.digital
                            </UnstyledLink>
                        </Text>
                    </Cell> */}
                    <Cell
                        // span={{ xxl: 8 / 12 }}
                        css={css`
                            display: none;
                            ${mediaquery(
                                "xxl",
                                `
                    display: block;
`
                            )}
                        `}
                    >
                        {config.navItems
                            .filter(item => item.title !== "Home")
                            .map(item => (
                                <Link to={item.href} key={item.title}>
                                    {item.title}
                                </Link>
                            ))}
                    </Cell>
                </Row>
            </Wrapper>
            <main>{children}</main>
            <footer>
                <Wrapper>
                    <Grid>
                        <Row>
                            <Cell span={{ sm: 11 / 12, lg: 12 / 12 }}>
                                <img
                                    src={stamp}
                                    css={css`
                                        max-width: 176px;
                                        margin: 0 auto 4em;
                                    `}
                                    alt="fucking.digital — fucking.awesome"
                                />
                            </Cell>
                        </Row>
                        <Row>
                            <Cell
                                span={{
                                    lg: 3 / 6
                                }}
                            >
                                <Text>
                                    © {new Date().getFullYear()}, Built with{" "}
                                    {`♥`}
                                </Text>
                            </Cell>
                            <Cell
                                span={{
                                    lg: 3 / 6
                                }}
                            >
                                <Text>
                                    <Link to="/imprint/">Imprint</Link>
                                </Text>
                            </Cell>
                        </Row>
                    </Grid>
                </Wrapper>
            </footer>
        </>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired
};

export default Layout;
