import theme from "../../styles/theme";
import adaptiveCssValue from "../../helpers/adaptiveCssValue";
import styled from "@emotion/styled";

const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: ${adaptiveCssValue(
        theme.breakpointByAlias["md"],
        theme.breakpointByAlias["x5l"],
        theme.grid.sm.gutter / -2,
        theme.grid.x5l.gutter / -2
    )};
`;

export default Row;
