import theme from "../../styles/theme";
import adaptiveCssValue from "../../helpers/adaptiveCssValue";
import styled from "@emotion/styled";

const Wrapper = styled.div`
    max-width: ${theme.breakpoints[theme.breakpoints.length - 1]}px;
    margin: 0 auto;
    width: calc(
        100% -
            ${adaptiveCssValue(
                theme.breakpointByAlias["md"],
                theme.breakpointByAlias["x5l"],
                theme.grid.sm.margin * 2,
                theme.grid.x5l.margin * 2
            )}
    );
`;

export default Wrapper;
