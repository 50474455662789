"use strict";

require("core-js/modules/es7.symbol.async-iterator");

require("core-js/modules/es6.symbol");

require("core-js/modules/es6.object.define-property");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
  return typeof obj;
} : function (obj) {
  return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
}; // TODO: rAF polyfill


exports["default"] = Watcher;

var _v = require("uuid/v1");

var _v2 = _interopRequireDefault(_v);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function isEqualTo(value1, value2) {
  var type = typeof value1 === "undefined" ? "undefined" : _typeof(value1);
  if (value1 instanceof Array) type = "array";

  switch (type) {
    case "string":
    case "number":
      return value1 === value2;
    // break;

    case "array":
      if (!(value2 instanceof Array) || value1.length != value2.length) return false;

      for (var i = 0, l = value1.length; i < l; i++) {
        if (value1[i] !== value2[i]) {
          return false;
        }
      }

      return true;
    // break;

    default:
      return false;
    // break;
  }
}

function Watcher(valueFunction) {
  var _this = this; // the mighty watcher list.


  this.watches = {};
  this.namespaces = {}; // setting for pausing the execution of the watches check

  this.paused = false; // holding the current rAF id

  var rafId = void 0;
  var value = undefined;

  var checkWatches = function checkWatches() {
    var newValue = valueFunction(); // if nothing changed we don’t have to do anything

    if (isEqualTo(value, newValue)) return; // else execute all functions

    for (var key in _this.watches) {
      var watch = _this.watches[key];
      if (watch.onchange) watch.onchange(newValue);

      if (watch.isMatch) {
        var isAMatch = watch.isMatch(newValue);

        if (isAMatch) {
          // execute onmatch callback
          if (watch.onmatch) watch.onmatch(newValue); // execute onappear callback if wasn’t a match before

          if (isAMatch != watch.matchState) {
            if (watch.onappear) watch.onappear(newValue);
            if (watch.onmatchchange) watch.onmatchchange(isAMatch, newValue);
          }
        } else {
          // execute ondismatch callback
          if (watch.ondismatch) watch.ondismatch(newValue); // execute ondisappear callback if wasn’t a match before

          if (isAMatch != watch.matchState) {
            if (watch.ondisappear) watch.ondisappear(newValue);
            if (watch.onmatchchange) watch.onmatchchange(isAMatch, newValue);
          }
        }

        watch.matchState = isAMatch;
      }
    }

    value = newValue;
  };

  var loop = function loop() {
    // requesting the next frame
    rafId = requestAnimationFrame(loop); // but do maxybe something before

    if (!_this.paused) checkWatches();
  }; // addWatch adds new watch to the watches list
  // options {
  //     namespace: string — (optional) can be helpful later to delete all watches within the same namespace,
  //     matchCondition: function — (optional) validates a match
  //     onchange: function — (optional) will be executed if the current value is not equal to old one
  //     onappear: function - (optional) just works, if matchCondition is set
  //     ondisappear: function - (optional) just works, if matchCondition is set
  //     onmatch: function - (optional) just works, if matchCondition is set
  //     ondismatch: function - (optional) just works, if matchCondition is set
  //     onmatchchange: function - (optional) if appears or disappears
  // } 


  var events = ["onchange", "onappear", "ondisappear", "onmatch", "ondismatch", "onmatchchange"];

  this.addWatch = function () {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var watcher = {};
    var id = (0, _v2["default"])();
    var changeEvent = false,
        // for validation check
    matchEvent = false,
        isMatch = typeof options.matchCondition === "function";

    for (var i = 0, len = events.length; i < len; i++) {
      if (typeof options[events[i]] !== "function") continue;

      if (events[i] !== "onchange") {
        // there is a match event/callback
        if (isMatch) {
          matchEvent = true;
        } else {
          // events only make sense with a condition
          continue;
        }
      }

      watcher[events[i]] = options[events[i]];
    } // passed all tests


    if (matchEvent) {
      watcher.isMatch = options.matchCondition;
      watcher.matchState = undefined;
    }

    watcher.id = id;

    if (options.namespace) {
      watcher.namespace = options.namespace;
      if (!_this.namespaces.hasOwnProperty(options.namespace)) _this.namespaces[options.namespace] = [];

      _this.namespaces[options.namespace].push(id);
    }

    _this.watches[id] = watcher;
    value = undefined; // make checkWatches believe the value changed

    if (!_this.paused) checkWatches();
    return id;
  };

  this.removeWatch = function (id) {
    if (_this.watches.hasOwnProperty(id)) {
      delete _this.watches[id];
    }
  };

  this.removeNamespace = function (namespace) {
    if (!_this.namespaces.hasOwnProperty(namespace)) return;

    for (var i = 0, len = _this.namespaces[namespace].length; i < len; i++) {
      _this.removeWatch(_this.namespaces[namespace][i]);
    }

    delete _this.namespaces[namespace];
  };

  this.play = function () {
    value = undefined;
    _this.paused = false;
    checkWatches();
  };

  this.pause = function () {
    _this.paused = true;
  };

  this.playPause = function () {
    if (_this.paused) _this.play();else _this.pause();
  };

  var initalize = function initalize() {
    if (typeof valueFunction !== "function") return false;
    loop();
  };

  this.kill = function () {
    if (rafId) cancelAnimationFrame(rafId);
  };

  initalize();
}