module.exports = {
    nameApiKey: "5d47219ad394e785c0f7c70b94f3cc60-f.d",
    nameApiUrl:
        "http://api.nameapi.org/rest/v5.3/email/disposableemailaddressdetector",
    getformKey: "55a1a9b2-26cc-4bb3-a702-45ecc282ac90",
    getformUrl: "https://getform.io/f/",
    calendlyUrl: "https://fckd.tl/z6RxY",
    navItems: [
        { title: "Home", href: "/" },
        { title: "Work", href: "/#work" },
        { title: "What we do", href: "/#what-we-do" },
        { title: "About", href: "/#about" },
        { title: "Contact", href: "/#contact" }
    ]
};
