import theme, { mediaquery } from "../../styles/theme";
import PropTypes from "prop-types";
import adaptiveCssValue from "../../helpers/adaptiveCssValue";
import isPropValid from "@emotion/is-prop-valid";
import styled from "@emotion/styled";
import { css } from "@emotion/core";

const roundNumber = value => Math.floor(value * 100 * 100) / 100;

const Cell = styled("div", {
    shouldForwardProp: prop => isPropValid(prop) && prop !== "offset"
})(
    css`
        box-sizing: border-box;
        padding: ${adaptiveCssValue(
            theme.breakpointByAlias["md"],
            theme.breakpointByAlias["x5l"],
            theme.grid.sm.gutter / 2,
            theme.grid.x5l.gutter / 2
        )};
        flex-grow: unset;
    `,
    props => {
        // span rules
        const breakpointsInUse = Object.keys(props.span);
        const firstBreakpointAlias = theme.breakpointAlias[0];
        const generateRule = v => `width: ${v}%;`;
        let rules = "";
        if (!breakpointsInUse.includes(firstBreakpointAlias)) {
            // default rule for smallest size
            rules += generateRule(100);
        }
        breakpointsInUse.forEach(k => {
            const v = props.span[k];
            if (k === firstBreakpointAlias) {
                rules += generateRule(roundNumber(v));
            } else {
                rules += mediaquery(k, generateRule(roundNumber(v)));
            }
        });
        return rules;
    },
    props => {
        // offset rules
        const breakpointsInUse = Object.keys(props.offset);
        const firstBreakpointAlias = theme.breakpointAlias[0];
        const generateRule = v => `margin-left: ${v}%;`;
        let rules = "";
        breakpointsInUse.forEach(k => {
            const v = props.offset[k];
            if (k === firstBreakpointAlias) {
                rules += generateRule(roundNumber(v));
            } else {
                rules += mediaquery(k, generateRule(roundNumber(v)));
            }
        });

        return rules;
    }
);
Cell.propTypes = {
    children: PropTypes.node,
    span: PropTypes.objectOf(PropTypes.number),
    offset: PropTypes.objectOf(PropTypes.number)
};
Cell.defaultProps = {
    children: null,
    span: {
        sm: 1
    },
    offset: {
        sm: 0
    }
};

export default Cell;
