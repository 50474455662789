/** @jsx jsx */
import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import theme, { mediaquery } from "../styles/theme";

const base = {
    fill: "none",
    strokeLinecap: "square",
    strokeWidth: 2,
    stroke: theme.colors.background
};

const keyframes = [
    {
        dashLength: 22,
        strokeDashoffset: [0, 0],
        x: 24,
        width: 20
    },
    {
        dashLength: 25.46,
        strokeDashoffset: [-63, -78],
        x: 44,
        width: 0
    }
    // {
    //     dashLength: 22,
    //     strokeDashoffset: [-122, -133],
    //     x: 27,
    //     width: 20
    // }
];

const Wrapper = styled.div`
    width: 48px;
    height: 48px;
    background-color: ${theme.colors.spot1};
    border-radius: 32px;
    cursor: pointer;
    backface-visibility: hidden;

    ${mediaquery(
        "lg",
        `
        width: 64px;
        height: 64px;
    `
    )}
`;

const Svg = styled.svg`
    width: 100%;
    height: 100%;
    > * {
        transition: 0.6s;
    }
`;
function MenuButton(props) {
    const key = props.switchedOn ? 1 : 0;
    const frame = keyframes[key];
    const strokeDasharray = `${frame.dashLength},1000`;
    const offset = frame.strokeDashoffset;
    const path1 = {
        ...base,
        strokeDasharray,
        strokeDashoffset: offset[0]
    };
    const path2 = {
        ...base,
        strokeDasharray,
        strokeDashoffset: offset[1]
    };
    const path3 = {
        x: frame.x,
        width: frame.width
    };
    return (
        <Wrapper onClick={props.onclick} className={props.className}>
            <Svg width="64px" height="64px" viewBox="0 0 64 64">
                <path
                    d="M43,40l-22,0c-6,0-12-4.8-12-14s7-10,14-3c11.5,11.6,17.5,17.6,18,18c6,6,10.6,0.1,11-6c0.5-7.9-3.2-11-9-11c-7.2,0-14.5,0-22,0"
                    {...path1}
                />
                <path
                    d="M21,24h22c6,0,8,1.4,7,6c-1,4.4-6.2,11.9-14.1,15.2C19.8,52,16.6,47.2,23,41l18-18c5-5,9.3,3.3,9.3,8.1S48.4,40,43,40H21"
                    {...path2}
                />
                <rect
                    fill={theme.colors.background}
                    y="31"
                    height="2"
                    {...path3}
                />
            </Svg>
        </Wrapper>
    );
}

MenuButton.propTypes = {
    switchedOn: PropTypes.bool,
    onclick: PropTypes.func,
    className: PropTypes.any
};
MenuButton.defaultProps = {
    switchedOn: false,
    onclick: null,
    className: null
};

export default MenuButton;
