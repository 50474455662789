import styled from "@emotion/styled";
import adaptiveCssValue from "../helpers/adaptiveCssValue";
import theme from "../styles/theme";

const Headline = styled.h2`
    ${props => {
        const fzValue = props.as === "h1" ? [3.4, 12] : [2.2, 6];
        return `
    font-size: ${adaptiveCssValue(
        theme.breakpointByAlias["md"],
        theme.breakpointByAlias["x5l"],
        ...fzValue
    )};
    line-height: ${
        props.as === "h1"
            ? adaptiveCssValue(
                  theme.breakpointByAlias["md"],
                  theme.breakpointByAlias["x5l"],
                  4.1,
                  13.2
              )
            : 1.4
    };
    `;
    }}
    margin: 0 0 0.5em -0.05em;
`;
const Text = styled.p`
    margin: 0 0 1em;
`;
const Smaller = styled.span`
    font-size: 0.8em;
    line-height: 1.5;
`;

const List = styled.ul`
    margin: 1em 0;
    padding: 0;
    list-style: none;

    > li {
        position: relative;
        margin: 0;
        padding: 0.75em 0;

        &:after {
            content: "";
            position: absolute;
            top: 100%;
            // top: calc(100% - 0.05rem);
            left: 0;
            width: 100%;
            border-top: solid 1px currentColor;
        }

        &:last-child:after {
            display: none;
        }
    }
`;

export { Headline, Text, Smaller, List };
